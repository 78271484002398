/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Dongle:wght@300;400;700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face {
    font-family: 'togedapp';
    src: url(./assets/fonts/togedapp.ttf);
}
body {
    background-color: #eff3f8;
    margin: 0;
    font-family: 'Raleway';
    font-size: 14px;
    color: var(--text-color);

    a, a:active, a:hover {
        color: #3872b8;
        text-decoration: none;
    }
}

:focus, button:focus{
    outline: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}

/* Global Classes  */
.global-box {
    padding: 20px;
    background-color: #fff;
    // margin: 10px;
    border-radius: 12px;
    box-shadow: 0 3px 5px #00000005,0 0 2px #0000000d,0 1px 4px #00000014;
    max-height: calc(100vh - 105px);
    overflow-y: auto;
    overflow-x: hidden;

    h1, h2, h3, h4, h5 {
        margin: 0;
    }

    h2 {
        margin-bottom: 20px;
    }

    h5 {
        border-bottom: solid 1px #ced4da8b;
        padding-bottom: 5px;
        margin: 10px 0px;
    }

    .field {
        label {
            font-size: 0.9rem;
        }
    }

    .p-datepicker {
        padding: unset;
        background: #ffffff;
        color: #495057;
        border: unset;
        border-radius: unset;
        margin-bottom: 20px;

        table {
            font-size: 16px;

            td {
                padding: 0px;

                span {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
}

.global-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        span {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 0.75rem;
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 10px 0px;

            li {
                padding: 10px 20px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                i {
                    margin-right: 10px;
                }
            }

            li:hover {
                background-color: #edf2f780;
                border-radius: 10px;
                cursor: pointer;
            }
        }
    }
}

// PrimeNG
:root .custom-spinner .p-progress-spinner-circle {
    animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;
}

@keyframes custom-progress-spinner-color {
    100%,
    0% {
        stroke: #5f9cd5;
    }
    40% {
        stroke: #3872b8;
    }
    66% {
        stroke: #5f9cd5;
    }
    80%,
    90% {
        stroke: #3872b8;
    }
}

@keyframes custom-progress-spinner-dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

.p-confirm-popup {
    max-width: 500px;
}

:root .p-toast {
    backdrop-filter: blur(10px) saturate(80%);

    .p-toast-message {
        border-radius: 20px;
        box-shadow: 0 3px 5px #00000005,0 0 2px #0000000d,0 1px 4px #00000014;
    }

    .p-toast-message.p-toast-message-success {
        background: #ffffff80;
        border: unset;
        border-width: unset;
        color: var(--text-color);
        font-size: 0.85em;

        .p-toast-message-icon {
            color: #439446;
        }

        .p-toast-icon-close {
            color: var(--text-color);
        }
    }

    .p-toast-message.p-toast-message-info {
        background: #ffffff80;
        border: unset;
        border-width: unset;
        color: var(--text-color);
        font-size: 0.85em;

        .p-toast-message-icon {
            color: #0891cf;
        }

        .p-toast-icon-close {
            color: var(--text-color);
        }
    }

    .p-toast-message.p-toast-message-warn {
        background: #ffffff80;
        border: unset;
        border-width: unset;
        color: var(--text-color);
        font-size: 0.85em;

        .p-toast-message-icon {
            color: #d9a300;
        }

        .p-toast-icon-close {
            color: var(--text-color);
        }
    }

    .p-toast-message.p-toast-message-error {
        background: #ffffff80;
        border: unset;
        border-width: unset;
        color: var(--text-color);
        font-size: 0.85em;

        .p-toast-message-icon {
            color: #e60017;
        }

        .p-toast-icon-close {
            color: var(--text-color);
        }
    }
}
/* Root Variables */
:root {
    --text-color: #495057;
    --logo-primary-color: rgb(56, 114, 184);
    --logo-secondary-color: #5f9cd5;
}